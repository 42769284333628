const myApplicationForm = {
  GET_MY_ADMISSION_FORMS_REQUEST: 'GET_MY_ADMISSION_FORMS_REQUEST',
  GET_MY_ADMISSION_FORMS_SUCCESS: 'GET_MY_ADMISSION_FORMS_SUCCESS',
  GET_MY_ADMISSION_FORMS_ERROR: 'GET_MY_ADMISSION_FORMS_ERROR',

  GET_MY_APPLICATION_FORM_REQUEST: 'GET_MY_APPLICATION_FORM_REQUEST',
  GET_MY_APPLICATION_FORM_SUCCESS: 'GET_MY_APPLICATION_FORM_SUCCESS',
  GET_MY_APPLICATION_FORM_ERROR: 'GET_MY_APPLICATION_FORM_ERROR',

  GET_MY_ADMISSIONS_REQUEST: 'GET_MY_ADMISSIONS_REQUEST',
  GET_MY_ADMISSIONS_SUCCESS: 'GET_MY_ADMISSIONS_SUCCESS',
  GET_MY_ADMISSIONS_ERROR: 'GET_MY_ADMISSIONS_ERROR',

  GENERATE_PAYMENT_REFERENCE_REQUEST: 'GENERATE_PAYMENT_REFERENCE_REQUEST',
  GENERATE_PAYMENT_REFERENCE_SUCCESS: 'GENERATE_PAYMENT_REFERENCE_SUCCESS',
  GENERATE_PAYMENT_REFERENCE_ERROR: 'GENERATE_PAYMENT_REFERENCE_ERROR',

  DOWNLOAD_APPLICATION_FORM_REQUEST: 'DOWNLOAD_APPLICATION_FORM_REQUEST',
  DOWNLOAD_APPLICATION_FORM_SUCCESS: 'DOWNLOAD_APPLICATION_FORM_SUCCESS',
  DOWNLOAD_APPLICATION_FORM_ERROR: 'DOWNLOAD_APPLICATION_FORM_ERROR',

  DOWNLOAD_ADMISSION_LETTER_REQUEST: 'DOWNLOAD_ADMISSION_LETTER_REQUEST',
  DOWNLOAD_ADMISSION_LETTER_SUCCESS: 'DOWNLOAD_ADMISSION_LETTER_SUCCESS',
  DOWNLOAD_ADMISSION_LETTER_ERROR: 'DOWNLOAD_ADMISSION_LETTER_ERROR',

  CHECK_PAYMENT_STATUS_SUCCESS: 'CHECK_PAYMENT_STATUS_SUCCESS',
  CHECK_PAYMENT_STATUS_REQUEST: 'CHECK_PAYMENT_STATUS_REQUEST',
  CHECK_PAYMENT_STATUS_ERROR: 'CHECK_PAYMENT_STATUS_ERROR',

  SHOW_PREVIEW_MODAL: 'SHOW_PREVIEW_MODAL',
  SET_SELECTED_APPLICATION_FORM: 'SET_SELECTED_APPLICATION_FORM',
  SHOW_VISA_MODAL: 'SHOW_VISA_MODAL',

  getMyAdmissionForms: (data) => ({
    type: myApplicationForm.GET_MY_ADMISSION_FORMS_REQUEST,
    data,
  }),

  checkPaymentStatus: (payload) => ({
    type: myApplicationForm.CHECK_PAYMENT_STATUS_REQUEST,
    payload,
  }),

  getMyApplicationForm: (formId) => ({
    type: myApplicationForm.GET_MY_APPLICATION_FORM_REQUEST,
    formId,
  }),

  getMyAdmissions: () => ({
    type: myApplicationForm.GET_MY_ADMISSIONS_REQUEST,
  }),

  generatePaymentReference: (formId) => ({
    type: myApplicationForm.GENERATE_PAYMENT_REFERENCE_REQUEST,
    formId,
  }),

  downloadApplicationForm: (applicationFormId) => ({
    type: myApplicationForm.DOWNLOAD_APPLICATION_FORM_REQUEST,
    applicationFormId,
  }),

  downloadAdmissionLetter: (
    applicationFormId,
    fromAdmissionStatus = false
  ) => ({
    type: myApplicationForm.DOWNLOAD_ADMISSION_LETTER_REQUEST,
    applicationFormId,
    fromAdmissionStatus,
  }),

  showPreviewModal: (e = false) => ({
    type: myApplicationForm.SHOW_PREVIEW_MODAL,
    e,
  }),

  showVisaModal: (e = false) => ({
    type: myApplicationForm.SHOW_VISA_MODAL,
    e,
  }),

  setSelectedApplicationForm: (e = {}) => ({
    type: myApplicationForm.SET_SELECTED_APPLICATION_FORM,
    e,
  }),
};

export default myApplicationForm;
